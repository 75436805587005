import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { headers } from 'src/lib/headers';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-get-premium-success',
  templateUrl: './get-premium-success.component.html',
  styleUrls: ['./get-premium-success.component.scss']
})
export class GetPremiumSuccessComponent implements OnInit {

  loading = true
  success = false
  error = ''

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private chat: ChatService
  ) { }

  async ngOnInit() {
    try {
      await <Promise<any>>this.http.post(environment.backendApi + `payment/premium`, { sessionId: this.route.snapshot.queryParams.session_id }, { headers: headers(this.chat.token) }).toPromise()
      this.loading = false
      this.success = true
    }
    catch(e) {
      this.loading = false
      this.error = e.error
    }
  }
}
