import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Chat } from '../chat.class';
import { addHours } from 'src/lib/add-days';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-notification-delay',
  templateUrl: './notification-delay.component.html',
  styleUrls: ['./notification-delay.component.scss']
})
export class NotificationDelayComponent {
  fromNow: string = ''
  isInThePast = false
  notificationsSupported = false

  years: number[] = []
  months: number[] = []
  days: number[] = []
  hours: number[] = []
  minutes: number[] = []

  year: number
  month: number
  day: number
  hour: number
  minute: number

  @Input() chat: Chat
  @Input() notifications: any[]

  constructor(private notificationsService: NotificationsService) {
    this.notificationsSupported = this.notificationsService.supported
    let date = addHours(new Date(), 1)
    let year = new Date().getFullYear()
    for (let i = 0; i < 60; i += 5) this.minutes.unshift(i)
    for (let i = 0; i < 24; i += 1) this.hours.unshift(i)
    for (let i = 1; i < 32; i += 1) this.days.unshift(i)
    for (let i = 1; i < 13; i += 1) this.months.unshift(i)
    for (let i = year; i < year + 6; i += 1) this.years.unshift(i)
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()
    this.hour = date.getHours()
    this.minute = Math.round(date.getMinutes() / 5) * 5
    this.recalculate()
  }

  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter()

  confirm() {
    let date = new Date(this.year, this.month - 1, this.day, this.hour, this.minute)
    let delay = date.getTime() - Date.now()
    this.onClose.emit(['set', delay])
  }

  deleteNotification(notification: any) {
    if (!confirm('Are you sure you want to delete this reminder?')) return
    this.onClose.emit(['delete', notification])
  }

  recalculate() {
    let date = new Date(this.year, this.month - 1, this.day, this.hour, this.minute)
    let delay = date.getTime() - Date.now()
    this.isInThePast = delay < 0
    this.fromNow = this.getRemaining(date)
  }

  getRemaining(date: Date) {
    let delay = date.getTime() - Date.now()
    let seconds = delay / 1000

    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    let minutes = Math.floor(seconds / 60);

    let string = ''
    string += `${days ? (days + ' day') : ''}${days > 1 ? 's' : ''}${days ? (hours ? ',' : minutes ? ' &' : '') : ''} `
    string += `${hours ? (hours + ' hour') : ''}${hours > 1 ? 's' : ''}${hours ? (minutes ? ' &' : '') : ''} `
    string += `${minutes ? (minutes + ' minute') : ''}${minutes > 1 ? 's' : ''}`

    return string
  }

  cancel() {
    this.onClose.emit()
  }

  nothing(e: Event) {
    e.stopPropagation()
  }
}
