import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-premium-feature-list',
  templateUrl: './premium-feature-list.component.html',
  styleUrls: ['./premium-feature-list.component.scss']
})
export class PremiumFeatureListComponent implements OnInit {
  @Input('centered') centered
  constructor() { }

  ngOnInit() {
  }

}
