import { MessageStatus } from './message-status';

export class Chat {
  public sentDate: Date = new Date()
  public customColor: string
  public replyToId: string
  public fakeReplyTo: string
  public audioRef: string
  public audioDuration: number
  public imageRef: string
  public imageAspectRatio: number
  public id: string = Date.now().toString() + Math.floor(Math.random() * 10000000).toString()

  constructor(public text: string) { }

  toJson() {
    return {
      text: this.text,
      id: this.id,
      sentDate: this.sentDate.toJSON(),
      customColor: this.customColor || '',
      replyToId: this.replyToId || '',
      fakeReplyTo: this.fakeReplyTo || '',
      audioRef: this.audioRef || '',
      audioDuration: this.audioDuration || 0,
      imageRefId: this.imageRef || '',
      imageAspectRatio: this.imageAspectRatio
    }
  }

  static fromJson(data: any) {
    const chat = new Chat(data.text)
    chat.id = data.id
    chat.customColor = data.customColor
    chat.sentDate = new Date(data.sentDate)
    chat.replyToId = data.replyToId
    chat.fakeReplyTo = data.fakeReplyTo
    chat.audioRef = data.audioRef
    chat.audioDuration = data.audioDuration
    chat.imageRef = data.imageRefId
    chat.imageAspectRatio = data.imageAspectRatio
    return chat
  }

  public copy() {
    const chat = new Chat(this.text)
    chat.id = this.id
    chat.sentDate = this.sentDate
    chat.customColor = this.customColor
    chat.replyToId = this.replyToId
    chat.fakeReplyTo = this.fakeReplyTo
    chat.audioRef = this.audioRef
    chat.audioDuration = this.audioDuration
    chat.imageRef = this.imageRef
    chat.imageAspectRatio = this.imageAspectRatio
    return chat
  }

  public getreplyToId(messages: Chat[]) {
    return messages.find(m => m.id === this.replyToId)
  }
}
