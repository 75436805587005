import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  public event(cat = '', action = '', label = '') {
    gtag('event', action, {
      event_category: cat,
      event_label: label
    });
  }
}
