import { Component, Output, EventEmitter } from '@angular/core';
import { ChatService } from '../chat.service';
import { ShareService } from '../share.service';
import { ThemeService } from '../theme.service';
import { Convo } from '../convo.class';

@Component({
  selector: 'app-convo-context',
  templateUrl: './convo-context.component.html',
  styleUrls: ['./convo-context.component.scss']
})
export class ConvoContextComponent {
  convo: Convo
  showEditField = false
  open = true
  origin = window.location.origin
  @Output() onClose: EventEmitter<any> = new EventEmitter()

  constructor(
    public chat: ChatService,
    public shareService: ShareService,
    public theme: ThemeService,
  ) { }

  show(convo: Convo) {
    this.convo = convo
    this.showEdit()
  }

  public close() {
    this.open = false
    setTimeout(() => this.onClose.emit(), 200)
  }

  public nothing(e: Event) {
    e.stopPropagation();
  }

  public showEdit() {
    this.showEditField = true
  }

  public confirmEdit(newName: string) {
    this.chat.editConvoName(this.convo, newName)
    this.close()
  }

  // public makePublic() {
  //   this.chat.makeConvoPublic(this.convo)
  //   if (this.shareService.canShare()) {
  //     this.shareService.share(this.origin + '/shared/' + this.convo.id)
  //   }
  // }

  // public makePrivate() {
  //   this.chat.makeConvoPrivate(this.convo)
  // }

  public unbockContext(e: Event) {
    e.stopPropagation()
  }
}
