import { Component, Sanitizer, SecurityContext } from '@angular/core';
import { ChatService } from '../chat.service';
import { countLines } from '../lib/count-lines';
import { HtmlifyService } from '../htmlify.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent {
  message: string = ''
  ctrlPressed: boolean = false
  field: HTMLTextAreaElement
  isMobile = false
  entryMode: string = 'text'
  entryModeSelectionHidden: boolean = false

  constructor(
    public chat: ChatService,
    public sanitizer: Sanitizer,
    private htmlify: HtmlifyService,
    ) {
    window.addEventListener('touchstart', function onFirstTouch() {
      this.isMobile = true
      window.removeEventListener('touchstart', onFirstTouch, false);
    }.bind(this), false)
  }

  setUpFieldControls() {
    this.field = document.querySelector('.field')

    this.field.onkeydown = (e: any) => {
      if (!this.isMobile) {
        if (e.key === 'Control') this.ctrlPressed = true
        if (e.key === 'Enter' && !this.ctrlPressed) e.preventDefault()
      }
    }
    this.field.onkeyup = (e: any) => {
      if (!this.isMobile) {
        if (e.key === 'Control') this.ctrlPressed = false
        if (e.key === 'Enter' && !this.ctrlPressed) this.submit(undefined, this.message)
        else if (e.key === 'Enter') this.message += '\n'
      }
    }
  }

  public submit(event: any = undefined, message: string) {
    if (event) {
      if (event.layerX < event.target.clientWidth - 40) return false
      if (event.layerY < event.target.clientHeight - 40) {
        setTimeout(() => event.target.blur())
        return false
      }
    }
    message = this.sanitizer.sanitize(SecurityContext.HTML, message)
    message = this.htmlify.convert(message)
    message = message.split('&#10;').join('\n')
    if (!message || !message.trim().length) return
    
    this.chat.addMessage(message)
    this.message = ''
  }

  switchEntryMode(mode: string) {
    this.entryModeSelectionHidden = mode !== 'text'
    this.entryMode = mode
  }

  entryContext(event: Event) {
    event.stopPropagation()
  }

  toggleItalic() {
    document.execCommand('italic');
  }

  toggleBold() {
    document.execCommand('bold');
  }

  public getHeight(): number {
    if (!this.field) return 0
    return 17 + (19 * countLines(this.field))
  }
}
