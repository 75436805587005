import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MediaService } from '../media.service';
import { AudioRecorderService } from '../audio-recorder.service';
import { Chat } from '../chat.class';

URL = window.URL || window.webkitURL;

@Component({
  selector: 'app-audio-playback',
  templateUrl: './audio-playback.component.html',
  styleUrls: ['./audio-playback.component.scss']
})
export class AudioPlaybackComponent implements OnDestroy, OnInit {
  @Input('chat') chat: Chat
  playing: boolean = false
  loading: boolean = false
  timing: any
  endWatcher: any
  duration: number
  audioDuration: number
  audio: HTMLAudioElement

  constructor(private media: MediaService, private audioService: AudioRecorderService) { }

  ngOnInit() {
    this.duration = 0
    this.loadAudio(true)
  }

  public async loadAudio(cacheOnly: boolean) {
    if (this.audio || this.loading) return
    this.loading = true
    // const blob = await this.media.getAsBlob(this.chat.audioRef, cacheOnly)
    this.audio = document.createElement('audio')
    this.audio.src = 'https://p.zelfchat.com/p/' + this.chat.audioRef
    await this.audioService.loadAudio(this.audio)
    this.loading = false
  }

  public async play() {
    if (this.loading) return
    await this.loadAudio(false)
    this.duration = 0
    this.playing = true
    this.audio.currentTime = 0
    this.timing = setInterval(() => {
      this.duration = Math.round((this.duration + 0.1) * 100) / 100
    }, 100)
    this.endWatcher = setTimeout(() => this.pause(), this.audio.duration * 1000)
    this.audio.play()
  }

  public pause() {
    this.audio.pause()
    this.playing = false
    clearInterval(this.timing)
    clearInterval(this.endWatcher)
    this.duration = 0
  }

  public togglePlayPause() {
    if (this.playing) this.pause()
    else this.play()
  }

  ngOnDestroy() {
    if (this.timing) clearInterval(this.timing)
    if (this.endWatcher) clearInterval(this.endWatcher)
    if (this.audio) this.audio.pause()
  }
}
