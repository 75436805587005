import { Component, Output, EventEmitter } from '@angular/core';
import { ThemeService } from '../theme.service';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent {
  @Output() onClose: EventEmitter<any> = new EventEmitter()
  primary: string;
  mode: string;
  open = true

  constructor(public theme: ThemeService, public chat: ChatService) {
    this.primary = this.theme.primaryColor
    this.mode = this.theme.mode
  }

  changePrimary(color: string) {
    this.primary = color
    this.theme.changePrimaryColor(this.primary)
  }

  close() {
    this.open = false
    setTimeout(() => this.onClose.emit(), 200)
  }

  nothing(e: Event) {
    e.stopPropagation()
  }

}
