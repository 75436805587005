import { Component, ViewChild } from '@angular/core';
import { ChatService } from './chat.service';
import { InstallService } from './install.service';
import { HeaderComponent } from './header/header.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { FullScreenService } from './full-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('header', { static: false}) header: HeaderComponent
  loading = true

  constructor(
    public chat: ChatService,
    private install: InstallService,
    private router: Router,
    public fullScreenService: FullScreenService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }
      if (event instanceof NavigationEnd) {
        this.loading = false;
      }

    })
    // if (this.install.isApp && this.router.url === '/') this.router.navigateByUrl('/app')
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
      window.scrollTo(0, 0)
    });
  }
}
