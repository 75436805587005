import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  mode: string
  primaryColor: string

  constructor() {
    this.changeMode(localStorage.getItem('mode') || 'light')
    this.changePrimaryColor(localStorage.getItem('primary-color') || '#1266d2')
  }

  public changeMode(newMode: string) {
    this.mode = newMode
    localStorage.setItem('mode', this.mode)
    if (this.mode === 'dark') document.body.classList.add('dark-mode')
    else document.body.classList.remove('dark-mode')
  }

  public changePrimaryColor(newColor: string) {
    this.primaryColor = newColor
    localStorage.setItem('primary-color', newColor)
    document.body.style.setProperty('--color-primary', newColor)
    document.querySelector('meta[name="theme-color"]').setAttribute("content", newColor)
  }
}
