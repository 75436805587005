import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Convo } from '../convo.class';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { headers } from 'src/lib/headers';
import { ChatService } from '../chat.service';
import { ShareService } from '../share.service';

@Component({
  selector: 'app-sharing-settings',
  templateUrl: './sharing-settings.component.html',
  styleUrls: ['./sharing-settings.component.scss']
})
export class SharingSettingsComponent implements OnInit {
  public convo: Convo
  public email: string = ''
  public loading = false
  public sharedWithList: any[]
  public invitesSentList: any[]
  public deleting: any = {}

  constructor(
    private chat: ChatService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public share: ShareService,
  ) { }

  ngOnInit() {
    this.convo = this.route.snapshot.data.convo
    this.calcLists()
  }

  calcLists() {
    this.sharedWithList = this.convo.sharedWith.filter(s => s.statusId === 1)
    this.invitesSentList = this.convo.sharedWith.filter(s => s.statusId === 0)
  }

  async invite(email: string) {
    if (this.loading) return
    if (this.convo.sharedWith.find(s => s.email === email)) return
    this.loading = true
    let params = { convoId: this.convo.id, email }
    let resp = await <Promise<any>>this.http.post(environment.backendApi + 'sharing', params, { headers: headers(this.chat.token) }).toPromise()
    this.convo.apply(Convo.fromJson(resp))
    this.loading = false
    this.email = ''
    this.calcLists()
  }

  async cancelInvite(shareId: string, email: string) {
    if (!confirm(`Are you sure you want to uninvite ${email}?`)) return
    this.deleting[shareId] = true
    let resp = await <Promise<any>>this.http.delete(environment.backendApi + `sharing/cancel/${this.convo.id}/${shareId}`, { headers: headers(this.chat.token) }).toPromise()
    this.convo.apply(Convo.fromJson(resp))
    this.calcLists()
    this.deleting[shareId] = false
  }

  async uninviteUser(userId: string, name: string) {
    if (!confirm(`Are you sure you want to remove ${name}?`)) return
    this.deleting[userId] = true
    let resp = await <Promise<any>>this.http.delete(environment.backendApi + `sharing/${this.convo.id}/${userId}`, { headers: headers(this.chat.token) }).toPromise()
    this.convo.apply(Convo.fromJson(resp))
    this.calcLists()
    this.deleting[userId] = false
  }

  async makePublic() {
    if (!confirm(`Are you sure you want to make ${this.convo.name} public?`)) return
    this.loading = true
    let resp = await <Promise<any>>this.http.put(environment.backendApi + `sharing/public/${this.convo.id}`, {}, { headers: headers(this.chat.token) }).toPromise()
    this.convo.apply(Convo.fromJson(resp))
    this.loading = false
  }

  async makePrivate() {
    if (!confirm(`Are you sure you want to make ${this.convo.name} private?`)) return
    this.loading = true
    let resp = await <Promise<any>>this.http.put(environment.backendApi + `sharing/private/${this.convo.id}`, {}, { headers: headers(this.chat.token) }).toPromise()
    this.convo.apply(Convo.fromJson(resp))
    this.loading = false
  }

  doShare() {
    this.share.share(`https://zelfchat.com/accept-invite/${this.convo.id}`)
  }

}
