import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Output() onConfirm: EventEmitter<any> = new EventEmitter()
  
  confirm(time: number) {
    this.onConfirm.emit(time);
  }

}
