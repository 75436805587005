import { Component, Output, EventEmitter } from '@angular/core';
import { ChatService } from '../chat.service';
import { MediaService } from '../media.service';
import { randomString } from '../lib/random-string';
import { b64ToImg } from 'src/lib/b64-to-img';

declare let loadImage: any
@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Output() onClose: EventEmitter<any> = new EventEmitter()
  b64: string
  loading: boolean = false

  constructor(
    private chat: ChatService,
    private media: MediaService,
  ) { }

  openFile() {
    document.getElementById('upload-file-input').click()
  }

  async upload (e: any) {
    const file = e.target.files[0]
    if (!file) return
    this.loading = true
    let quality = this.chat.hasPremium ? 0.5 : 0.4
    let maxWidth = this.chat.hasPremium ? 1920 : 560
    loadImage(
      file, async (canvas: HTMLCanvasElement) => {
        this.b64 = canvas.toDataURL('image/jpeg', quality)
        await this.confirm()
        this.loading = false
      }, { maxWidth, orientation: true, canvas: true }
    );
  }

  async confirm() {
    const imageName = randomString(11) + '.jpg'
    const resp = await this.media.putB64('image/jpeg', this.b64, imageName)
    if (!resp) return this.close()
    let img = await b64ToImg(this.b64)
    let aspectRatio = img.naturalHeight / img.naturalWidth
    this.chat.addImageMessage(imageName, aspectRatio)
    this.close()
  }

  close() {
    this.onClose.emit()
  }
}
