import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { headers } from 'src/lib/headers';
import { ChatService } from '../chat.service';
import { loadScript } from 'src/lib/load-script';

declare let Stripe: any

@Component({
  selector: 'app-get-premium',
  templateUrl: './get-premium.component.html',
  styleUrls: ['./get-premium.component.scss']
})
export class GetPremiumComponent {

  agree = false
  loading = false

  constructor(
    private chat: ChatService,
    private http: HttpClient
  ) { }

  async getPremium() {
    this.loading = true
    let resp = await <Promise<any>>this.http.get(environment.backendApi + `payment/premium`, { headers: headers(this.chat.token) }).toPromise()
    await loadScript('https://js.stripe.com/v3/')
    const stripe = Stripe('pk_test_CbOHPwqUri9mNolJNgA4HaSr006ki42CVF');
    await stripe.redirectToCheckout({ sessionId: resp.orderId })
    this.loading = false
  }

}
