import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { headers } from 'src/lib/headers';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent {

  public loading = false

  constructor(
    private chat: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) { }

  async accept() {
    let params = { token: this.route.snapshot.params.token }

    this.loading = true;
    try {
      await <Promise<any>>this.http.post(environment.backendApi + 'sharing/accept', params, { headers: headers(this.chat.token) }).toPromise()
      await this.chat.getUserInfo()
      await this.chat.switchConvo(this.chat.convos[this.chat.convos.length - 1])
      this.router.navigate(['app'])
    } 
    catch(e) {
      console.error(e.error);
      if (e.error === 'Already accepted') {
        this.router.navigate(['app'])
      } else {
        this.router.navigate(['invalid-invite'])
      }
    }
  }

}
