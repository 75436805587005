import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AudioRecorderService } from '../audio-recorder.service';
import { ChatService } from '../chat.service';
import { MediaService } from '../media.service';
import { randomString } from '../lib/random-string';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss']
})
export class AudioRecorderComponent implements OnInit, OnDestroy {
  @Output() onClose: EventEmitter<any> = new EventEmitter()
  audio: HTMLAudioElement
  recording: boolean = false
  maxTimeChecker: any
  recLengthInterval: any
  endWatcher: any
  duration: number
  maxDuration: number = 1000 * 60
  playing: boolean = false
  playbackTimeInterval: any
  audioDuration: number = 0
  audioBlob: Blob
  submitting: boolean = false

  constructor(
    public audioService: AudioRecorderService,
    public chat: ChatService,
    public media: MediaService
  ) { }

  ngOnInit() {
    this.startRecording()
  }

  async startRecording() {
    this.duration = 0
    try {
      await this.audioService.startRecording()
      this.recording = true
      this.recLengthInterval = setInterval(() => this.duration += 0.1, 100)
      this.maxTimeChecker = setTimeout(() => this.stopRecording(), this.maxDuration)
    } catch(err) {
      this.close()
    }
  }

  public async stopRecording() {
    clearInterval(this.recLengthInterval)
    clearInterval(this.maxTimeChecker)
    this.audioBlob = await this.audioService.stopRecording()
    this.audio = document.createElement('audio')
    this.audio.src = URL.createObjectURL(this.audioBlob)
    this.recording = false
    this.duration = 0
    await this.audioService.loadAudio(this.audio)
    this.audioDuration = Math.round(this.audio.duration * 100) / 100
  }

  public play() {
    this.reset()
    this.audio.play()
    this.playing = true
    this.playbackTimeInterval = setInterval(() => this.duration += 0.1, 100)
    this.endWatcher = setTimeout(() => this.pause(), this.audio.duration * 1000)
  }

  public pause() {
    this.audio.pause()
    this.playing = false
    this.duration = 0
    clearInterval(this.playbackTimeInterval)
  }

  public close() {
    this.onClose.emit()
  }

  public async submit() {
    this.submitting = true
    const name = randomString(11) + '.aac'
    let blob = await this.media.compressAudio(this.audioBlob)
    const resp = await this.media.putFile(new File([blob], name, { type: 'audio/aac' }))
    if (!resp) return this.close()
    this.chat.addVoiceMessage(this.audio.duration * 1000, name)
    this.submitting = false
    this.close()
  }

  public reset() {
    this.audio.currentTime = 0
    this.duration = 0
  }

  ngOnDestroy() {
    if (this.recording) this.audioService.stopRecording()
    if (this.playbackTimeInterval) clearInterval(this.playbackTimeInterval)
    if (this.recLengthInterval) clearInterval(this.recLengthInterval)
    if (this.maxTimeChecker) clearTimeout(this.maxTimeChecker)
    if (this.endWatcher) clearTimeout(this.endWatcher)
    if (this.audio && !this.audio.paused) this.audio.pause()
  }
}
