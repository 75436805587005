import { Component, ViewChild, Input } from '@angular/core';
import { ChatService } from '../chat.service';
import { Chat } from '../chat.class';
import { countLines } from '../lib/count-lines';
// import { AuthService } from '../auth.service';
import { Convo } from '../convo.class';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent {
  @ViewChild('context', { static: false }) context: any
  @Input('convo') convo: Convo
  @Input('viewOnly') viewOnly: boolean = false
  messages: Chat[] = []
  windowHeight: number = Infinity
  selectedMessage: Chat
  selectedMessages: Chat[] = []
  highlightedChat: Chat
  showMessageContext = false
  entryBoxHeight: number

  constructor(
    public chat: ChatService,
    public notifications: NotificationsService
  ) {
    this.windowHeight = window.innerHeight
    window.onresize = () => { // Android keyboard open fix
      if (window.innerHeight < this.windowHeight) this.scrollToBottom()
      this.windowHeight = window.innerHeight
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      const view = document.getElementById('view')
      view && (view.scrollTop = view.scrollHeight)
    })
  }

  showContextMenu(event: MouseEvent, message: Chat) {
    if (this.convo.ownerName) return
    if (this.viewOnly) return
    if (this.showMessageContext) return
    event.preventDefault()
    this.showMessageContext = true
    this.selectedMessages.push(message)
    setTimeout(() => this.context.show(message))
  }

  close() {
    this.showMessageContext = false
    this.selectedMessages = []
  }

  getMessages() {
    let newMessages = this.chat.getMessages(this.convo)
    if (newMessages.length > this.messages.length) this.scrollToBottom()
    this.messages = newMessages
    return false
  }

  selectMessage(event: Event, message: Chat = undefined) {
    if (!message) return
    if (this.showMessageContext) {
      if (this.selectedMessages.includes(message)) {
        this.selectedMessages.splice(this.selectedMessages.indexOf(message), 1)
        this.context.remove(message)
        if (!this.selectedMessages.length) this.close()
      } else {
        this.selectedMessages.push(message)
        this.context.add(message)
      }
    } else {
      this.selectedMessage = message
    }
    event.stopPropagation()
  }

  replyTo(message: Chat) {
    if (this.convo.ownerName) return
    if (!message.text) return
    if (this.viewOnly) return
    // if (this.auth.loggingIn) return
    this.chat.replyTo = message
    document.getElementById('entry-field').focus()
    this.scrollToBottom()
  }

  goToMessage(event: Event, chat: Chat) {
    event.stopPropagation()
    this.highlightedChat = chat
    const el = document.getElementById('chat-' + chat.id)
    const view = document.getElementById('view')
    const toMove = el.getBoundingClientRect().top
    view.scrollTop += toMove - window.innerHeight / 2

    setTimeout(() => {
      if (this.highlightedChat === chat) this.highlightedChat = undefined
    }, 300)
  }

  isMessageSelected(message: Chat) {
    if (!this.showMessageContext) return false
    if (this.selectedMessages.indexOf(message) !== -1) return true
  }

  detectFieldSizeChange() {
    const field = document.querySelector('.field')
    if (!field) return
    let height = countLines(<HTMLTextAreaElement>field)
    if (this.chat.replyTo) height += 38
    if (this.entryBoxHeight && height !== this.entryBoxHeight) {
      this.scrollToBottom()
    }
    this.entryBoxHeight = height
    return false
  }

  showClearSearch() {
    // (chat.filter || chat.colorFilter) && !chat.searchOpen
    // if (this.chat.searchOpen) return false
    if (this.chat.filter) return true
    if (this.chat.colorFilter) return true
  }

  nothing() { }// this function is just there so that swiping to open the menu works properly
}
