import { Directive, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appInit]'
})
export class InitDirective {
  @Output() appInit: EventEmitter<any> = new EventEmitter()

  ngOnInit() {
    setTimeout(() => this.appInit.emit())
  }
}