import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ChatService } from '../chat.service';
import { ShareService } from '../share.service';
import { InstallService } from '../install.service';
import { Router } from '@angular/router';
import { SocialUser, AuthService } from 'angularx-social-login';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Output() onClose: EventEmitter<any> = new EventEmitter()
  @Output() onAction: EventEmitter<any> = new EventEmitter()
  @Input('viewOnly') viewOnly: boolean = false
  open: boolean = true
  showTheme: boolean = false
  user: SocialUser

  constructor(
    public chat: ChatService,
    public share: ShareService,
    public installService: InstallService,
    public router: Router,
    private authService: AuthService
  ) {
    this.authService.authState.subscribe(user => user && !this.user && (this.user = user))
  }

  toHome() {
    this.router.navigateByUrl('/')
    this.close()
  }

  toProfile() {
    this.router.navigateByUrl('/profile')
    this.close()
  }

  toPremium() {
    this.router.navigateByUrl('/get-premium')
    this.close()
  }

  toConvos() {
    this.onAction.emit('openConvos')
    this.close()
  }
  
  toSearch() {
    this.onAction.emit('openSearch')
    this.close()
  }

  logout() {
    this.authService.signOut()
    setTimeout(() => window.location.href = window.location.origin, 500)
  }

  close() {
    this.open = false
    setTimeout(() => this.onClose.emit(), 250)
  }

  doShowTheme() {
    this.showTheme = true
  }

  doHideTheme() {
    this.showTheme = false
  }

  nothing(e: Event) {
    e.stopPropagation()
  }

  invite() {
    this.share.share('ZelfChat - A free app for talking to yourself', 'https://zelfchat.com')
  }

  install() {
    this.installService.install().then(resp => {
      console.log(resp);
      if (resp) this.close()
    })
  }
}
