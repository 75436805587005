import { Component, Input } from '@angular/core';
import { Chat } from '../chat.class';
import { FullScreenService } from '../full-screen.service';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent {
  @Input('chat') chat: Chat
  constructor(private fullScreenService: FullScreenService) { }

  openFullScreen() {
    this.fullScreenService.open(this.chat.imageRef)
  }

}
