import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit, OnDestroy {

  active = true
  @ViewChild('adContainer', { static: true }) adContainer: ElementRef

  constructor() {}

  ngOnInit() {
    this.loadAd()
  }

  loadAd() {
    this.adContainer.nativeElement.innerHTML = ''
    let isDesktop = window.innerWidth >= 720
    let adId = isDesktop ? 'b0f3dc24c8c712d4f7df590ebf9700bf' : 'c71e52d1a3e89dbfb577b656b5a0bff1'

    if (isDesktop) {
      window['atOptions'] = { 'key': 'b0f3dc24c8c712d4f7df590ebf9700bf', 'format': 'iframe', 'height': 600, 'width': 160, 'params': {} };
    } else {
      window['atOptions'] = { 'key': 'c71e52d1a3e89dbfb577b656b5a0bff1', 'format': 'iframe', 'height': 50, 'width': 320, 'params': {} }
    }

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = 'http' + (location.protocol === 'https:' ? 's' : '') + '://www.bcloudhost.com/' + adId + '/invoke.js';
    this.adContainer.nativeElement.appendChild(s);
    setTimeout(() => {
      if (this.active) this.loadAd()
    }, 1000 * 60)
  }

  ngOnDestroy() {
    this.active = false
  }

}
