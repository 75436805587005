import { Component, Output, EventEmitter, OnInit, Input, Sanitizer, SecurityContext } from '@angular/core';
import { countLines } from '../lib/count-lines';
import { HtmlifyService } from '../htmlify.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  field: HTMLTextAreaElement
  ctrlPressed: boolean = false
  @Input('text') text: string
  @Output() onConfirm: EventEmitter<any> = new EventEmitter()

  constructor(public sanitizer: Sanitizer, private htmlify: HtmlifyService) {
    // This is to undo the html sanitization that has happened.
    let el = document.createElement('span');
    el.innerHTML = this.text || '';
    this.text = el.innerText
  }

  ngOnInit() {
    this.field = document.querySelector('.edit-field')
    this.field.onkeydown = (e: any) => {
      if (e.key === 'Control') this.ctrlPressed = true
      if (e.key === 'Enter' && !this.ctrlPressed) e.preventDefault()
    }
    this.field.onkeyup = (e: any) => {
      if (e.key === 'Control') this.ctrlPressed = false
      if (e.key === 'Enter' && !this.ctrlPressed) this.confirm()
      else if (e.code === 'Enter') this.text += '\n'
    }
  }

  public confirm() {
    this.text = this.sanitizer.sanitize(SecurityContext.HTML, this.text)
    this.text = this.htmlify.convert(this.text)
    this.text = this.text.split('&#10;').join('\n')
    if (!this.text.length || !this.text.trim().length) return
    this.onConfirm.emit(this.text)
  }

  public getHeight() {
    if (!this.field) return 0
    return 17 + (19 * countLines(this.field))
  }

  entryContext(event: Event) {
    event.stopPropagation()
  }
}
