import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class InstallService {
  prompt: any
  isApp: boolean = window.matchMedia('(display-mode: standalone)').matches

  constructor(private router: Router, private analytics: AnalyticsService) {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      console.log(e)
      this.prompt = e
    });
  }

  public canInstall(): boolean {
    return !!this.prompt
  }

  public goToApp() {
    this.router.navigateByUrl('/app')
  }

  public async install(): Promise<boolean> {
    this.prompt.prompt()
    return this.prompt.userChoice.then((resp: any) => {
      if (resp.outcome === 'accepted') {
        this.prompt = undefined
        this.analytics.event('install')
        this.goToApp()
        return true
      }
      return false
    })
  }
}
