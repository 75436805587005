export const addDays = (date: Date, days: number) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const addHours = (date: Date, hours: number) => {
  var result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
}