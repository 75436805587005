import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ChatService } from '../chat.service';
import { Convo } from '../convo.class';
import { ConvoContextComponent } from '../convo-context/convo-context.component';

@Component({
  selector: 'app-convo-list',
  templateUrl: './convo-list.component.html',
  styleUrls: ['./convo-list.component.scss']
})
export class ConvoListComponent {
  @Output() onClose: EventEmitter<any> = new EventEmitter()
  @ViewChild('context', { static: false }) context: ConvoContextComponent
  showMessageContext = false
  showNewConvo = false
  convoList: Convo[]
  sharedConvoList: Convo[]
  open = true

  constructor(public chat: ChatService) {
    this.convoList = this.chat.convos.filter(c => !c.ownerName)
    this.sharedConvoList = this.chat.convos.filter(c => !!c.ownerName)
  }

  showContextMenu(convo: Convo, event: MouseEvent = undefined) {
    if (event) event.preventDefault()
    this.showMessageContext = true
    setTimeout(() => { this.context.show(convo) })
  }

  closeContextMenu() {
    this.showMessageContext = false
    this.close()
  }

  async createConvo(name: string) {
    this.chat.createConvo(name)
    this.showNewConvo = false
    this.close()
  }

  close() {
    this.open = false
    setTimeout(() => this.onClose.emit(), 250)
  }

  public deleteConvo(convo: Convo) {
    if (confirm(`Are you sure you want to delete ${convo.name}? This action cannot be undone.`)) {
      this.chat.deleteConvo(convo)
      this.close()
    }
  }

  nothing(e: Event) {
    e.stopPropagation()
  }

}
