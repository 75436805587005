import { Component } from '@angular/core';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-undo',
  templateUrl: './undo.component.html',
  styleUrls: ['./undo.component.scss']
})
export class UndoComponent {

  constructor(public chat: ChatService) { }

}
