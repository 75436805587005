import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FullScreenService {
  imageRef: string
  constructor() { }

  open(imageRef: string) {
    this.imageRef = imageRef
  }
}
