import { Component, ViewChild } from '@angular/core';
import { ChatService } from '../chat.service';
import { HeaderComponent } from '../header/header.component';
import { ThemeService } from '../theme.service';
import { AnalyticsService } from '../analytics.service';
import { SocialUser, AuthService } from "angularx-social-login";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
  @ViewChild('header', { static: false }) header: HeaderComponent
  public isMobile = window.innerWidth < 700
  public showAd = parseInt(localStorage.getItem('visitCount')) > 1
  public user: SocialUser

  constructor(
    public chat: ChatService,
    public analytics: AnalyticsService,
    private theme: ThemeService, // just here for it to work..
    private authService: AuthService
  ) {
    let visitCount = parseInt(localStorage.getItem('visitCount')) || 0
    visitCount += 1
    localStorage.setItem('visitCount', visitCount.toString())
    this.authService.authState.subscribe(user => user && !this.user && (this.user = user))
  }

  preventContext(e: Event) {
    e.preventDefault()
  }

  hideAd() {
    this.showAd = false
    this.analytics.event('advert', 'hide')
  }

  openMenu() {
    this.header.showMenu = true
  }
}
