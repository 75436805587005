import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChatComponent } from './chat/chat.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { HelpComponent } from './help/help.component';
import { AboutComponent } from './about/about.component';
import { AuthResolverService } from './auth.resolver.service';
import { AuthResolverNegativeService } from './auth-resolver-negative.service';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { ConvoResolverService } from './convo-resolver.service';
import { SharingSettingsComponent } from './sharing-settings/sharing-settings.component';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { InvalidInviteComponent } from './invalid-invite/invalid-invite.component';
import { GetPremiumComponent } from './get-premium/get-premium.component';
import { GetPremiumSuccessComponent } from './get-premium-success/get-premium-success.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'app',
    component: ChatComponent,
    resolve: {
      user: AuthResolverService
    }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    resolve: {
      user: AuthResolverService
    }
  },
  {
    path: 'get-premium',
    component: GetPremiumComponent,
    resolve: {
      user: AuthResolverService
    }
  },
  {
    path: 'get-premium/success',
    component: GetPremiumSuccessComponent,
    resolve: {
      user: AuthResolverService
    }
  },
  {
    path: 'sharing-settings/:convoId',
    component: SharingSettingsComponent,
    resolve: {
      convo: ConvoResolverService
    }
  },
  {
    path: 'accept-invite/:token',
    component: AcceptInviteComponent,
    resolve: {
      user: AuthResolverService
    }
  },
  {
    path: 'invalid-invite',
    component: InvalidInviteComponent,
    resolve: {
      user: AuthResolverService
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      user: AuthResolverNegativeService
    }
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
