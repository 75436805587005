import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ChatService } from './chat.service';
import { headers } from 'src/lib/headers';
import { SwPush } from '@angular/service-worker';
import { randomString } from './lib/random-string';
import { Chat } from './chat.class';
import { AuthService, SocialUser } from 'angularx-social-login';
import { Convo } from './convo.class';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  supported = 'Notification' in window
  deviceId: string
  VAPID_PUBLIC_KEY = "BNdT-8q_YpiSWZJ2A9IgU1t-8Hdx7GOVDRuldl8qDrF3lePoq7dEiqDK6t6im3Oyg-2pjLQY1_r_WiJUfsVIGfc"
  user: SocialUser
  notifications: any[] = []

  constructor(
    private http: HttpClient,
    private chat: ChatService,
    private swPush: SwPush,
    private authService: AuthService
  ) {
    this.deviceId = localStorage.getItem('deviceId') || randomString(32)
    localStorage.setItem('deviceId', this.deviceId)
    this.authService.authState.subscribe(user => {
      if (!user || this.user) return
      this.getNotifications()
    })
  }

  public async sendNotification(chat: Chat, convo: Convo, delay: number) {
    let localTime = new Date().getTime()
    let data = await this.requestPermission()
    let resp = await <Promise<any>>this.http.post(environment.backendApi + 'notifications', { chatId: chat.id, convoId: convo.id, delay, data, deviceId: this.deviceId }, { headers: headers(this.chat.token) }).toPromise()
    if (resp) {
      this.applyNotifications(resp.notifications, resp.serverTime, localTime)
    }
  }

  private async getNotifications() {
    let localTime = new Date().getTime()
    let resp = await <Promise<any>>this.http.get(environment.backendApi + 'notifications', { headers: headers(this.chat.token) }).toPromise()
    this.notifications = []
    this.applyNotifications(resp.notifications, resp.serverTime, localTime)
  }

  private applyNotifications(notifications: any[], serverTime: number, localTime = new Date().getTime()) {
    notifications.forEach(n => {
      let c = 1000 * 60 * 5;
      let date = new Date(n.sendTime - serverTime + localTime)
      n.sendTime = new Date(Math.round(date.getTime() / c) * c)
    })
    notifications.forEach(n => this.notifications.push(n))
  }

  public async deleteNotification(notification: any) {
    try {
      await <Promise<any>>this.http.delete(environment.backendApi + 'notifications/' + notification._id, { headers: headers(this.chat.token) }).toPromise()
    } catch(e) {}
    this.notifications.splice(this.notifications.indexOf(notification), 1)
  }

  private async requestPermission() {

    // return {
    //   endpoint: 'test',
    //   expirationTime: null,
    //   keys: {
    //     p256dh: 'test',
    //     auth: 'test'
    //   }
    // }

    try {
      return await this.swPush.requestSubscription({serverPublicKey: this.VAPID_PUBLIC_KEY})
    } catch(err) {
      console.error("Could not subscribe to notifications", err);
    }
  }

  public getChatNotifications(chat: Chat) {
    return this.notifications.filter(n => {
      if (n.chatId != chat.id) return false
      return n.sendTime.getTime() > Date.now()
    })
  }
}
