import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SocialUser, AuthService } from 'angularx-social-login';


@Injectable({
  providedIn: 'root',
})
export class AuthResolverNegativeService implements Resolve<SocialUser> {
  constructor(private authService: AuthService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Promise<SocialUser> {
    return new Promise(resolve => {
      let navigated = false
      this.authService.readyState.subscribe(resp => {
        setTimeout(() => resp.length === 2 && !navigated && resolve())
      })
      this.authService.authState.subscribe((user) => {
        if (navigated) return
        if (user) {
          navigated = true
          if (route.params.returnUrl) {
            setTimeout(() => {
              this.router.navigate(JSON.parse(decodeURIComponent(route.params.returnUrl)))
            }, 100)
          } else {
            this.router.navigate(['app'])
          }
        }
      });
    })
  }
}