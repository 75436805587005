var recordAudio;
var workerPath = location.origin + '/ffmpeg_asm.js';
function processInWebWorker() {
  var blob = URL.createObjectURL(new Blob(['importScripts("' + workerPath + '");var now = Date.now;function print(text) {postMessage({"type" : "stdout","data" : text});};onmessage = function(event) {var message = event.data;if (message.type === "command") {var Module = {print: print,printErr: print,files: message.files || [],arguments: message.arguments || [],TOTAL_MEMORY: message.TOTAL_MEMORY || false};postMessage({"type" : "start","data" : Module.arguments.join(" ")});postMessage({"type" : "stdout","data" : "Received command: " +Module.arguments.join(" ") +((Module.TOTAL_MEMORY) ? ".  Processing with " + Module.TOTAL_MEMORY + " bits." : "")});var time = now();var result = ffmpeg_run(Module);var totalTime = now() - time;postMessage({"type" : "stdout","data" : "Finished processing (took " + totalTime + "ms)"});postMessage({"type" : "done","data" : result,"time" : totalTime});}};postMessage({"type" : "ready"});'], {
    type: 'application/javascript'
  }));
  var worker = new Worker(blob);
  URL.revokeObjectURL(blob);
  return worker;
}
var worker;
export const wavToOgg = (audioBlob: Blob): Promise<Blob> => {
  return new Promise(resolve => {
    var aab;
    var buffersReady;
    var workerReady;
    var posted;
    var fileReader = new FileReader();
    fileReader.onload = function () {
      aab = this.result;
      postMessage();
    };
    fileReader.readAsArrayBuffer(audioBlob);
    if (!worker) {
      worker = processInWebWorker();
    }
    worker.onmessage = function (event) {
      var message = event.data;
      if (message.type == "ready") {
        workerReady = true;
        if (buffersReady)
          postMessage();
      } else if (message.type == "done") {
        var result = message.data[0];
        var blob = new File([result.data], 'test.aac', {
          type: 'audio/aac'
        });
        resolve(blob)
      }
    };
    var postMessage = function () {
      posted = true;
      worker.postMessage({
        type: 'command',
        arguments: '-i audio.wav -c:a aac -b:a 96k -strict experimental output.aac'.split(' '),
        files: [
          {
            data: new Uint8Array(aab),
            name: "audio.wav"
          }
        ]
      });
    };
  })
}