import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HtmlifyService {

  public convert(str: string) {
    const rows = str.split('\n')
    .map(row => {
      return row
      .split('*(').join('<b>').split(')*').join('</b>')
      .split('/(').join('<i>').split(')/').join('</i>')
    })

    return rows.join('\n')
  }
}
