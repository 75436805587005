import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let key = Object.keys(args[0])[0]
    let val = args[0][key]
    return value.filter(v => v[key] === val)
  }

}
