import { Component, Output, EventEmitter } from '@angular/core';
import { Chat } from '../chat.class';
import { ChatService } from '../chat.service';
import { ShareService } from '../share.service';
import { copyTextToClipboard } from '../lib/copy-text-to-clipboard';
import { ThemeService } from '../theme.service';
import { NotificationsService } from '../notifications.service';
@Component({
  selector: 'app-message-context',
  templateUrl: './message-context.component.html',
  styleUrls: ['./message-context.component.scss']
})
export class MessageContextComponent {
  messages: Chat[] = []
  showEditField = false
  showNotificationSet = false
  showcolorPicker = false
  showNotificationDelay = false
  open = true
  chatNotifications: any[]
  @Output() onClose: EventEmitter<any> = new EventEmitter()

  constructor(
    public chat: ChatService,
    public shareService: ShareService,
    public theme: ThemeService,
    public notifications: NotificationsService
  ) { }

  show(message: Chat) {
    this.messages = [message]
    this.chatNotifications = this.notifications.getChatNotifications(this.messages[0])
  }

  add(message: Chat) {
    this.messages.push(message)
  }

  remove(message: Chat) {
    this.messages.splice(this.messages.indexOf(message), 1)
  }

  public close() {
    this.open = false
    setTimeout(() => this.onClose.emit(), 200)
  }

  public nothing(e: Event) {
    e.stopPropagation();
  }

  public delete() {
    let atLeastOneMessageHasMedia = this.messages.find(m => m.imageRef || m.audioRef)
    if (!atLeastOneMessageHasMedia || confirm('Deleting a message with a picture or voice clip cant be undone. Are you sure you want to continue?')) {
      this.chat.invalidateBackup()
      if (!atLeastOneMessageHasMedia) this.chat.storeBackup('delete')
      this.messages.forEach(message => this.chat.deleteMessage(message))
      this.close()
    }
  }

  public reply() {
    this.chat.replyTo = this.messages[0]
    document.getElementById('entry-field').focus()
    this.close()
  }

  public removeReplyTo() {
    this.chat.removeReplyTo(this.messages[0])
    this.close()
  }

  public showEdit() {
    this.showEditField = true
  }

  public confirmEdit(newText: string) {
    this.chat.editMessage(this.messages[0], newText)
    this.close()
  }

  public copy() {
    let text = this.messages.map(m => m.text).join('\n')
    copyTextToClipboard(text)
    this.close()
  }

  public share() {
    let text = this.messages[0].text
    if (text && (this.messages[0].imageRef || this.messages[0].audioRef)) text += ' - '
    if (this.messages[0].imageRef) text += 'p.zelfchat.com/p/' + this.messages[0].imageRef
    if (this.messages[0].audioRef) text += 'p.zelfchat.com/p/' + this.messages[0].audioRef

    this.shareService.share(text)
    this.close()
  }

  public changeColor(newColor: string) {
    this.showcolorPicker = false
    this.messages.forEach(message => this.chat.changeMessageColor(message, newColor))
    this.close()
  }

  openNotificationSet() {
    this.showNotificationSet = true
  }

  public notify() {
    this.showNotificationDelay = true
  }

  public onNotificationDelayClose(event: any) {
    if (!event) return this.close()
    if (event[0] === 'set') {
      this.showNotificationDelay = false
      if (event[1] <= 0) return
      this.notifications.sendNotification(this.messages[0], this.chat.activeConvo, event[1])
      this.close()
    }
    else if (event[0] === 'delete') {
      this.notifications.deleteNotification(event[1])
      this.close()
    }
  }
}


// { "publicKey": "BNdT-8q_YpiSWZJ2A9IgU1t-8Hdx7GOVDRuldl8qDrF3lePoq7dEiqDK6t6im3Oyg-2pjLQY1_r_WiJUfsVIGfc", "privateKey": "2Om_85gLqSV6-0cy0BvxByaXvUjtdT_oHzsBRs6IuCk" }