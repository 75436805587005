import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Convo } from './convo.class';
import { ChatService } from './chat.service';
import { AuthResolverService } from './auth.resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ConvoResolverService implements Resolve<Convo> {

  constructor(
    private chat: ChatService,
    private auth: AuthResolverService
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<Convo> {
    await this.auth.resolve(route)
    const convo = this.chat.convos.find(c => c.id === route.params.convoId)
    await this.chat.loadConvo(convo)
    return convo
  }
}
