import { Injectable } from '@angular/core'

declare global {
  interface Window { AudioContext: any; webkitAudioContext: any, webkitURL: any }
}
declare let Recorder: any

URL = window.URL || window.webkitURL;
var AudioContext = window.AudioContext || window.webkitAudioContext;

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {
  recorder: any
  stream: MediaStream
  audioContext: any
  constructor() { }

  public async startRecording() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        this.audioContext = new AudioContext()
        this.stream = stream
        const input = this.audioContext.createMediaStreamSource(stream)
        this.recorder = new Recorder(input, { numChannels: 2, type: 'ogg' })
        this.recorder.record()
        resolve()
      }).catch(err => {
        alert(err)
        reject()
      })
    })
  }

  public async stopRecording(): Promise<Blob> {
    this.recorder.stop()
    return new Promise(resolve => {
      this.recorder.exportWAV((blob: Blob) => resolve(blob))
      this.stream.getAudioTracks()[0].stop()
    })
  }

  public async loadAudio(audio: HTMLAudioElement): Promise<void> {
    audio.load()
    return new Promise(resolve => setTimeout(() => {
        resolve()
    }, 200))
  }
}
