import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  @Input('color') color: string
  @Input('isInline') isInline: boolean = false
  @Input('allowNegative') allowNegative: boolean = false
  @Input('type') type: string
  @Output() onConfirm: EventEmitter<any> = new EventEmitter()

  private oldColor: string
  public negative: boolean = false

  changeColor(color: string) {
    this.color = color
    this.negative = this.allowNegative && this.oldColor === this.color && !this.negative
    this.oldColor = this.color
    if (this.allowNegative) this.onConfirm.emit([this.color, this.negative]);
    else this.onConfirm.emit(this.color);
  }
}
