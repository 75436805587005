import { Component } from '@angular/core';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent {
  show: boolean = false

  constructor() {
    setTimeout(() => {
      this.show = !localStorage.getItem('gdpr-status')
    }, 2000)
  }

  accept() {
    this.show = false
    localStorage.setItem('gdpr-status', 'accepted')
  }

}
