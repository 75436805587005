import { Injectable } from '@angular/core';
import { Chat } from './chat.class';
import { ToastService } from './toast.service';
import { AuthService, SocialUser } from "angularx-social-login";
import { AnalyticsService } from './analytics.service';
import { Convo } from './convo.class';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { headers } from 'src/lib/headers';
import { MediaService } from './media.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public convos: Convo[] = []
  public activeConvo: Convo
  public replyTo: Chat
  filter: string = ''
  colorFilter: string = ''
  colorFilterNegative: boolean = false
  searchOpen: boolean = false
  public token: string
  public hasPremium: boolean
  public loading = false
  returnWatcher: any
  public backedUpconvo: any
  private deleteBackupTimer: any
  actionToUndo: string
  user: SocialUser
  fullyLoaded = false

  constructor(
    public toast: ToastService,
    private analytics: AnalyticsService,
    private authService: AuthService,
    private http: HttpClient,
    private media: MediaService
  ) { }

  public async startWatchingLogin() {
    return new Promise(resolve => {
      if (this.fullyLoaded) return resolve()
      this.authService.authState.subscribe(async user => {
        if (!user || this.user) return
        this.user = user

        let type = user.provider.toLowerCase()
        let response: any

        if (type === 'google') {
          response = await <Promise<{ token: string }>>this.http.post(environment.backendApi + 'login/google', { idToken: user.idToken }).toPromise()
        }

        if (type === 'facebook') {
          response = await <Promise<{ token: string }>>this.http.post(environment.backendApi + 'login/facebook', { access_token: user.authToken }).toPromise()
        }

        if (!response.token) return
        this.token = response.token

        let userData = response.userData
        this.hasPremium = userData.hasPremium

        this.convos = userData.convos.map((c: any) => {
          c._id = c.convoId
          c.chats = []
          return Convo.fromJson(c)
        })

        if (!this.convos.filter(c => !c.ownerName).length) this.createConvo('Default', true)
        else await this.switchConvo(this.convos.find(c => c.isDefault) || this.convos[0], false)
        this.fullyLoaded = true
        resolve()
      })
    })
  }
  
  public async switchConvo(convo: Convo, doRequest = true) {
    if (this.activeConvo) this.activeConvo.isDefault = false
    this.activeConvo = convo
    convo.isDefault = true
    await this.loadConvo(convo)
    if (!doRequest) return
    await <Promise<any>> this.http.put(environment.backendApi + 'convos/default/' + convo.id, {}, { headers: headers(this.token) }).toPromise()
  }

  public async loadConvo(convo: Convo) {
    let data = await this.getConvoById(convo.id)
    convo.apply(data);
    convo.loaded = true
  }

  public async getUserInfo() {
    let resp = await <Promise<any>>this.http.get(environment.backendApi + 'user', { headers: headers(this.token) }).toPromise()
    this.hasPremium = resp.hasPremium
    this.convos = resp.convos.map((c: any) => {
      c._id = c.convoId
      c.chats = []
      return Convo.fromJson(c)
    })
    await this.switchConvo(this.convos.find(c => c.isDefault) || this.convos[0], false)
  }

  public async createConvo(name: string, isDefault = false) {
    let newConvo = new Convo(name)
    newConvo.isDefault = isDefault
    let data = await <Promise<any[]>> this.http.post(environment.backendApi + 'convos', newConvo.toJson(), { headers: headers(this.token) }).toPromise()
    let convo = Convo.fromJson(data)
    convo.name = name
    this.convos.push(convo)
    this.switchConvo(convo, !isDefault)
    if (this.convos.length > 1) this.analytics.event('chat', 'add-convo')
  }

  public async deleteConvo(convo: Convo = this.activeConvo) {
    convo.chats.forEach(chat => {
      if (chat.audioRef) this.media.deleteFile(chat.audioRef)
      if (chat.imageRef) this.media.deleteFile(chat.imageRef)
    })
    const resetActiveConvo = convo === this.activeConvo
    await <Promise<any[]>> this.http.delete(environment.backendApi + 'convos/' + convo.id, { headers: headers(this.token) }).toPromise()

    this.convos.splice(this.convos.indexOf(convo), 1)
    if (resetActiveConvo) this.switchConvo(this.convos[0])
    this.analytics.event('chat', 'delete-convo')
  }

  public async editConvoName(convo: Convo, newName: string) {
    convo.name = newName
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/edit-name/' + convo.id, {name: newName}, { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'edit-convo')
  }

  // public async makeConvoPublic(convo: Convo) {
  //   convo.shared = true
  //   // this.saveConvo(convo)
  //   await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/change-shared/' + convo.id, { shared: true }, { headers: headers(this.token) }).toPromise()
  //   this.analytics.event('chat', 'made-convo-public')
  // }

  // public async makeConvoPrivate(convo: Convo) {
  //   convo.shared = false
  //   await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/change-shared/' + convo.id, { shared: false }, { headers: headers(this.token) }).toPromise()
  //   this.analytics.event('chat', 'made-convo-private')
  // }

  public async addMessage(text: string): Promise<Chat> {
    this.invalidateBackup()
    let newChat = new Chat(text.trim())
    if (this.replyTo) newChat.replyToId = this.replyTo.id
    this.activeConvo.chats.push(newChat)
    this.replyTo = undefined
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/add-chat/' + this.activeConvo.id, newChat.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'add-message')
    return newChat
  }

  public async addVoiceMessage(duration: number, name: string): Promise<Chat> {
    this.invalidateBackup()
    let newChat = new Chat('')
    newChat.audioRef = name
    newChat.audioDuration = duration
    if (this.replyTo) newChat.replyToId = this.replyTo.id
    this.activeConvo.chats.push(newChat)
    this.replyTo = undefined
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/add-chat/' + this.activeConvo.id, newChat.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'add-voice-message')
    return newChat
  }

  public async addImageMessage(name: string, aspectRatio: number): Promise<Chat> {
    this.invalidateBackup()
    let newChat = new Chat('')
    newChat.imageAspectRatio = aspectRatio
    newChat.imageRef = name
    if (this.replyTo) newChat.replyToId = this.replyTo.id
    this.activeConvo.chats.push(newChat)
    this.replyTo = undefined
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/add-chat/' + this.activeConvo.id, newChat.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'add-image-message')
    return newChat
  }

  public async storeBackup(type: string, convo: Convo = this.activeConvo) {
    if (!convo.loaded) await this.loadConvo(convo)
    this.actionToUndo = type
    this.backedUpconvo = convo.toJson()
    if (this.deleteBackupTimer) clearTimeout(this.deleteBackupTimer)
    this.deleteBackupTimer = setTimeout(() => {
      this.backedUpconvo = undefined
    }, 5000)
  }

  public invalidateBackup() {
    this.actionToUndo = undefined
    if (this.deleteBackupTimer) clearTimeout(this.deleteBackupTimer)
    this.backedUpconvo = undefined
  }

  public async undo() {
    this.actionToUndo = undefined
    clearTimeout(this.deleteBackupTimer)
    this.activeConvo = Convo.fromJson(this.backedUpconvo)
    this.backedUpconvo = undefined
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/' + this.activeConvo.id, this.activeConvo.toJson(), { headers: headers(this.token) }).toPromise()
  }

  public async deleteMessage(chat: Chat) {
    await Promise.all(this.getRepliesTo(chat).map(c => {
      c.fakeReplyTo = chat.text
      c.replyToId = undefined
      return this.http.put(environment.backendApi + 'convos/update-chat/' + this.activeConvo.id + '/' + c.id, c.toJson(), { headers: headers(this.token) }).toPromise()
    }))
    if (chat.audioRef) this.media.deleteFile(chat.audioRef)
    if (chat.imageRef) this.media.deleteFile(chat.imageRef)
    let index = this.activeConvo.chats.indexOf(chat)
    let id = chat.id
    this.activeConvo.chats.splice(index, 1)
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/delete-chat/' + this.activeConvo.id + '/' + id, {}, { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'delete-message')
  }

  public async editMessage(chat: Chat, text: string, updateDate = false) {
    this.storeBackup('edit')
    chat.text = text.trim()
    if (updateDate) chat.sentDate = new Date()
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/update-chat/' + this.activeConvo.id + '/' + chat.id, chat.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'edit-message', 'text')
  }

  public async removeReplyTo(chat: Chat) {
    this.storeBackup('delete')
    chat.replyToId = undefined
    chat.fakeReplyTo = undefined
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/update-chat/' + this.activeConvo.id + '/' + chat.id, chat.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'remove-reply-to')
  }

  public async changeMessageColor(chat: Chat, newColor: string) {
    if (newColor === 'theme') newColor = undefined
    chat.customColor = newColor
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/update-chat/' + this.activeConvo.id + '/' + chat.id, chat.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'edit-message', 'color')
  }

  public getMessages(convo: Convo = this.activeConvo) {
    return convo.chats.filter(message => {
      if (!this.filter && !this.colorFilter) return true
      if (this.colorFilter) {
        if (this.colorFilterNegative) {
          if (!message.customColor && this.colorFilter === 'theme') return false
          if (message.customColor && message.customColor === this.colorFilter) return false
        } else {
          if (!message.customColor && this.colorFilter !== 'theme') return false
          if (message.customColor && message.customColor !== this.colorFilter) return false
        }
      }
      if (!this.filter) return true
      return message.text.toLowerCase().indexOf(this.filter) > -1
    }).slice(-200).sort((a, b) => a.sentDate > b.sentDate ? 1 : -1)
  }

  public getConvos() {
    return this.convos
  }

  public async getConvoById(id: string) {
    let resp = await <Promise<any>>this.http.get(environment.backendApi + 'convos/' + id, { headers: headers(this.token) }).toPromise()
    return Convo.fromJson(resp)
  }

  public getReplyTo(chat: Chat, convo = this.activeConvo) {
    return convo.chats.find(c => c.id === chat.replyToId)
  }

  public getRepliesTo(chat: Chat, convo = this.activeConvo) {
    return convo.chats.filter(c => c.replyToId === chat.id)
  }

  public async clearAll(convo = this.activeConvo) {
    convo.chats.forEach(chat => {
      if (chat.audioRef) this.media.deleteFile(chat.audioRef)
      if (chat.imageRef) this.media.deleteFile(chat.imageRef)
    })
    convo.chats = []
    await <Promise<any[]>>this.http.put(environment.backendApi + 'convos/' + this.activeConvo.id, this.activeConvo.toJson(), { headers: headers(this.token) }).toPromise()
    this.analytics.event('chat', 'clear-messages')
  }

  public search() {
    this.filter = this.filter.toLowerCase()
  }

  public searchColor(color: string, negative: boolean = false) {
    this.colorFilter = color
    this.colorFilterNegative = negative
  }

  public clearSearch() {
    this.filter = ''
    this.colorFilter = ''
    this.colorFilterNegative = false
  }

  public allowNewConvo() {
    return this.convos.filter(c => !c.ownerName).length < (this.hasPremium ? 10 : 3)
  }

  public allowDeleteConvo() {
    return this.convos.filter(c => !c.ownerName).length > 1
  }
}
