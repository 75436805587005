import { Component, Input } from '@angular/core';
import { ChatService } from '../chat.service';
import { NetworkService } from '../network.service';
import { Convo } from '../convo.class';
import { AuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public showMenu: boolean = false
  showSearch: boolean = false
  showConvoList: boolean = false
  user: SocialUser

  @Input('viewOnly') viewOnly: boolean = false
  @Input('noSearch') noSearch: boolean = false
  @Input('convo') convo: Convo

  constructor(
    public chat: ChatService,
    public network: NetworkService,
    private authService: AuthService
  ) {
    this.authService.authState.subscribe(user => user && !this.user && (this.user = user))
    window.onkeyup = (e: any) => {
      if (e.key === 'Escape' && this.showSearch) this.closeSearch()
      if (e.key === 'Escape' && this.showMenu) this.closeMenu()
    }
  }
  
  openMenu() {
    this.showMenu = true
  }

  closeMenu() {
    this.showMenu = false
  }

  openConvoList() {
    this.showConvoList = true
  }

  closeConvoList() {
    this.showConvoList = false
  }

  onAction(event: string) {
    if (event === 'openConvos') {
      this.openConvoList()
    }
    if (event === 'openSearch') {
      this.openSearch()
    }
  }

  openSearch() {
    this.showSearch = true
  }

  closeSearch() {
    this.showSearch = false
    this.chat.searchOpen = false
    if (!this.chat.getMessages().length) this.chat.clearSearch()
  }
}
