import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { b64toBlob } from './lib/b64-to-blob';
import { wavToOgg } from 'src/lib/wav-to-ogg';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(private http: HttpClient) { }

  putB64 = async (type: string, b64: string, name: string) => {
    let blob = await b64toBlob(b64.split(',')[1], type)
    let file = new File([blob], name)
    return this.putFile(file)
  }

  putFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = <{ status: 'success' | 'error' }>await this.http.post('https://p.zelfchat.com/upload_file.php', formData).toPromise()
    return response && response.status === 'success'
  }

  deleteFile = async (name: string) => {
    return <{ status: 'success' | 'error' }>await this.http.post('https://p.zelfchat.com/delete_file.php', { name }).toPromise()
  }

  compressAudio = async (blob: Blob) => await wavToOgg(blob)
}
