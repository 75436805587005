import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {
  
  transform(value: Date, args?: any): any {
    const diff = value.getTime() - Date.now()
    
    if (diff > 1000 * 60 * 60 * 24) {
      return `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`
    }

    if (diff > 1000 * 60 * 60) {
      const val = Math.floor(diff / (1000 * 60 * 60))
      return `${val} hour${val === 1 ? '' : 's'}`
    }

    if (diff > 1000 * 60) {
      const val = Math.floor(diff / (1000 * 60))
      return `${val} minute${val === 1 ? '' : 's'}`
    }
    
    const val = Math.floor(diff / (1000))
    return `${val} second${val === 1 ? '' : 's'}`
  }

}
