import { Component } from '@angular/core';
import { InstallService } from '../install.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    public install: InstallService,
    private router: Router
  ) {
    if (this.install.isApp && this.router.url === '/') this.router.navigateByUrl('/app')
  }

}
