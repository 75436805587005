import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter()
  open: boolean = true

  constructor(public chat: ChatService) { }

  ngOnInit() {
    this.chat.searchOpen = true
  }

  close() {
    this.open = false
    setTimeout(() => this.onClose.emit(), 200)
  }

  nothing(e: Event) {
    e.stopPropagation()
  }

  doSearchColor(color: string, negative: boolean) {
    this.chat.searchColor(color, negative)
  }

}
