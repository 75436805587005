import { Component } from '@angular/core';
import { SocialUser, AuthService } from 'angularx-social-login';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  user: SocialUser

  constructor(
    private authService: AuthService,
    public chat: ChatService
  ) {
    this.authService.authState.subscribe(user => user && !this.user && (this.user = user))
  }

  logout() {
    this.authService.signOut()
    setTimeout(() => window.location.href = window.location.origin, 500)
  }

}
