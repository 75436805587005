import { Injectable } from '@angular/core';
import { Toast } from './toast.class';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toast: Toast

  public add(message: string, actionText: string = 'Undo', delay: number = 5000): Promise<boolean> {
    return new Promise((resolve) => {
      let action = {
        text: actionText,
        do: () => {
          this.toast = undefined
          clearTimeout(timeout)
          clearTimeout(timeout2)
          resolve(true)
        }
      }
      let timeout = setTimeout(() => {
        resolve(false)
        this.toast = undefined
      }, delay)
      let timeout2 = setTimeout(() => {
        this.toast.open = false
      }, delay - 200)
      this.toast = new Toast(message, action, delay)
    })
  }
}
