import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor() { }

  public canShare(): boolean {
    let x = <any>navigator
    return !!x.share
  }

  public share(text: string, url: string = undefined) {
    let x = <any>navigator
    if (x.share) {
      x.share({
        title: 'ZelfChat',
        text: text,
        url: url
      })
      .then(() => console.log('Successful share'))
      .catch((error: string) => console.log('Error sharing:' + error));
    }
  }
}
