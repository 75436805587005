import { Chat } from './chat.class';

export class Convo {
  id: string
  chats: Chat[] = []
  public = false
  loaded = false
  isDefault = false
  ownerName: string
  sharedWith: {
    email: string,
    name: string,
    userId: string,
    statusId: number,
    shareId: string
  }[]

  constructor(public name: string) { }

  toJson() {
    return {
      _id: this.id,
      name: this.name,
      ownerName: this.ownerName,
      public: this.public,
      sharedWith: this.sharedWith || [],
      isDefault: this.isDefault,
      chats: this.chats.map(chat => chat.toJson())
    }
  }

  static fromJson(data: any) {
    const convo = new Convo(data.name)
    convo.id = data._id
    convo.public = data.public
    convo.ownerName = data.ownerName
    convo.isDefault = data.isDefault
    convo.chats = data.chats.map(Chat.fromJson)
    convo.sharedWith = data.sharedWith || []
    return convo
  }

  public copy() {
    const convo = new Convo(this.name)
    convo.id = this.id
    convo.public = this.public
    convo.isDefault = this.isDefault
    convo.ownerName = this.ownerName
    convo.sharedWith = this.sharedWith || []
    convo.chats = this.chats.map(chat => chat.copy())
    return convo
  }

  public apply(convo: Convo) {
    this.id = convo.id
    this.public = convo.public
    this.isDefault = convo.isDefault
    this.sharedWith = convo.sharedWith || []
    this.chats = convo.chats
  }
}
