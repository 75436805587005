import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SocialUser, AuthService } from 'angularx-social-login';
import { ChatService } from './chat.service';


@Injectable({
  providedIn: 'root',
})
export class AuthResolverService implements Resolve<SocialUser> {
  constructor(private authService: AuthService, private router: Router, private chat: ChatService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<SocialUser> {
    return new Promise(resolve => {
      let resolved = false
      this.authService.readyState.subscribe(resp => {
        if (resp.length === 2) {
          setTimeout(() => {
            resolved || this.router.navigate(['login', { returnUrl: encodeURIComponent(JSON.stringify(route.url.map(r => r.path))) }])
            resolved = true
          })
        }
      })
      this.authService.authState.subscribe(async user => {
        if (user && !resolved) {
          resolved = true
          await this.chat.startWatchingLogin()
          resolve(user)
        }
      });
    })
  }
}