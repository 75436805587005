import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { environment } from '../environments/environment'

import { AutofocusModule } from 'angular-autofocus-fix'
import { InlineSVGModule } from 'ng-inline-svg'

import { AppComponent } from './app.component'
import { ViewComponent } from './view/view.component';
import { EntryComponent } from './entry/entry.component';
import { HeaderComponent } from './header/header.component';
import { MessageContextComponent } from './message-context/message-context.component';
import { EditComponent } from './edit/edit.component';
import { MenuComponent } from './menu/menu.component';
import { SearchComponent } from './search/search.component';
import { ToastComponent } from './toast/toast.component';
import { LoginComponent } from './login/login.component';
import { ThemeComponent } from './theme/theme.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ConvoContextComponent } from './convo-context/convo-context.component';
import { DatePickerComponent } from './date-picker/set-time.component';
import { DatetimePipe } from './datetime.pipe';
import { HomeComponent } from './home/home.component';
import { ChatComponent } from './chat/chat.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { LongPressDirective } from './long-press.directive';
import { InitDirective } from './init.directive';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { AudioPlaybackComponent } from './audio-playback/audio-playback.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageViewComponent } from './image-view/image-view.component';
import { HelpComponent } from './help/help.component';
import { AboutComponent } from './about/about.component';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConvoListComponent } from './convo-list/convo-list.component';
import { UndoComponent } from './undo/undo.component';
import { ProfileComponent } from './profile/profile.component';
import { LoaderComponent } from './loader/loader.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';
import { FullScreenComponent } from './full-screen/full-screen.component';
import { SharingSettingsComponent } from './sharing-settings/sharing-settings.component';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { InlineLoaderComponent } from './inline-loader/inline-loader.component';
import { FilterPipe } from './filter.pipe';
import { InvalidInviteComponent } from './invalid-invite/invalid-invite.component';
import { NotificationDelayComponent } from './notification-delay/notification-delay.component';
import { AdComponent } from './ad/ad.component';
import { GetPremiumComponent } from './get-premium/get-premium.component';
import { UnlockComponent } from './unlock/unlock.component';
import { PremiumFeatureListComponent } from './premium-feature-list/premium-feature-list.component';
import { PremiumPricingComponent } from './premium-pricing/premium-pricing.component';
import { GetPremiumSuccessComponent } from './get-premium-success/get-premium-success.component';

declare var Hammer: any

export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new Hammer(element, { touchAction: "pan-y" })
  }
}

const config = new AuthServiceConfig([
  { id: GoogleLoginProvider.PROVIDER_ID, provider: new GoogleLoginProvider("346052902996-krlj0qvcelh2pkbcr4sooutipd5b66he.apps.googleusercontent.com") },
  { id: FacebookLoginProvider.PROVIDER_ID, provider: new FacebookLoginProvider("427435188094908") }
]);

export const provideConfig = () => config

@NgModule({
  declarations: [
    AppComponent,
    ViewComponent,
    EntryComponent,
    HeaderComponent,
    MessageContextComponent,
    EditComponent,
    MenuComponent,
    SearchComponent,
    ToastComponent,
    LoginComponent,
    ThemeComponent,
    ColorPickerComponent,
    ConvoContextComponent,
    DatePickerComponent,
    DatetimePipe,
    HomeComponent,
    ChatComponent,
    PrivacyComponent,
    TermsComponent,
    GdprComponent,
    LongPressDirective,
    InitDirective,
    AudioRecorderComponent,
    AudioPlaybackComponent,
    ImageUploadComponent,
    ImageViewComponent,
    HelpComponent,
    AboutComponent,
    ConvoListComponent,
    UndoComponent,
    ProfileComponent,
    LoaderComponent,
    SimpleHeaderComponent,
    FullScreenComponent,
    SharingSettingsComponent,
    AcceptInviteComponent,
    InlineLoaderComponent,
    FilterPipe,
    InvalidInviteComponent,
    NotificationDelayComponent,
    AdComponent,
    GetPremiumComponent,
    UnlockComponent,
    PremiumFeatureListComponent,
    PremiumPricingComponent,
    GetPremiumSuccessComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AutofocusModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    AppRoutingModule,
    SocialLoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
],
  bootstrap: [AppComponent]
})
export class AppModule { }